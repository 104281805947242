<template>
  <section id="planner" v-if="appData.users && selectedSeason">
    <header>
      <label
        @mouseover="toggleSelector('seasons')"
        @mouseout="toggleSelector(null)"
        :class="{ active: openedSelector === 'seasons' }"
      >
        <span class="label">Saison</span>
        <span class="selection">{{ selectedSeasonShortTitle }}</span>
        <ul>
          <li
            v-for="(season, i) in appData.seasons"
            :key="i"
            :class="{ active: i === selectedSeasonIndex }"
            @click="
              selectedSeasonIndex = i;
              toggleSelector(null);
            "
          >
            {{ season.title }}
          </li>
        </ul>
      </label>
      <label
        @mouseover="toggleSelector('users')"
        @mouseout="toggleSelector(null)"
        :class="{ active: openedSelector === 'users' }"
      >
        <span class="label">Person</span>
        <span class="selection">{{ selectedUser.code }}</span>
        <ul>
          <li
            v-for="(user, i) in appData.users"
            :key="i"
            :class="{ active: i === selectedUserIndex }"
            @click="
              selectedUserIndex = i;
              toggleSelector(null);
            "
          >
            <strong>{{ user.code }}</strong> · {{ user.familyname }}
            {{ user.firstname }}
          </li>
        </ul>
      </label>
      <label
        @mouseover="toggleSelector('views')"
        @mouseout="toggleSelector(null)"
        :class="{ active: openedSelector === 'views' }"
      >
        <span class="label">Ansicht</span>
        <span class="selection">{{ selectedView.label }}</span>
        <ul>
          <li
            @click="
              selectedViewIndex = 0;
              toggleSelector(null);
            "
            :class="{ active: 0 === selectedViewIndex }"
          >
            Perioden
          </li>
          <li
            @click="
              selectedViewIndex = 1;
              toggleSelector(null);
            "
            :class="{ active: 1 === selectedViewIndex }"
          >
            Tage
          </li>
        </ul>
      </label>
      <label class="button" @click="exporting = true">
        <span class="label">xls</span>
        <span class="selection">⬇</span>
      </label>
    </header>

    <article v-if="selectedView.name === 'periods'" class="periods">
      <header>
        <div v-if="staff && !expired" class="opened">
          <strong
            >Eintragungen für die Saison
            {{ selectedSeasonShortTitle }}
            sind durch den Pikett-Stab bis 15. Mai
            {{ this.selectedSeason.start.substr(0, 4) }} möglich.</strong
          >
          <ol>
            <li>Oben die Saison auswählen</li>
            <li>Die im Pikett-Einsatzplan zu bearbeitende Person auswählen</li>
            <li>
              Die Ansicht &quot;Perioden&quot; wählen (Stabsmitglieder können
              nur hier schreiben)
            </li>
            <li>
              In die gewünschte Tabellezelle klicken, um
              <ul>
                <li>
                  eine noch freie Pikett-Periode mit der gewählten Person zu
                  belegen
                </li>
                <li>die gewählte Person aus der Pikett-Periode zu entfernen</li>
              </ul>
            </li>
            <li>
              Telefonnummern auf der Kontaktliste überprüfen und Abweichungen
              melden, diese Angaben sind verbindlich
            </li>
          </ol>
        </div>
        <div v-if="staff && expired" class="closed">
          <strong
            >Das Zeitfenster für Pikettdienst-Eintragungen für die Saison
            {{ selectedSeasonShortTitle }}
            ist seit 16. Mai
            {{ this.selectedSeason.start.substr(0, 4) }} geschlossen.</strong
          ><br />
          Für Änderungwünsche Disponent/PL kontaktieren.
        </div>
        <div v-if="!staff" class="closed">
          Das Ändern dieser Liste ist den Beteiligten des Transitgas-Pikett
          vorbehalten. Sie können hier nur lesen.
        </div>
      </header>
      <table>
        <thead>
          <tr>
            <th>KW</th>
            <th>Datum</th>
            <th>Pikett</th>
            <th>Stv</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(period, i) in selectedSeason.periods"
            :key="i"
            :class="{ current: isCurrentPeriod(period) }"
          >
            <th>{{ period.kw }}</th>
            <th>{{ period.title }}</th>
            <td
              v-if="(staff && !expired) || admin"
              class="clickable"
              :data-name="'p_' + period.start + '_' + period.end"
              @click="cellClicked($event)"
              @mouseover="cellMouseOver($event)"
              @mouseout="cellMouseOut($event)"
            >
              <span class="orig">{{
                getUsersForPeriod(period, "p").join(", ")
              }}</span>
              <span class="alt">{{ selectedUser.code }}</span>
            </td>
            <td v-else>{{ getUsersForPeriod(period, "p").join(", ") }}</td>
            <td
              v-if="(staff && !expired) || admin"
              class="clickable"
              :data-name="'s_' + period.start + '_' + period.end"
              @click="cellClicked($event)"
              @mouseover="cellMouseOver($event)"
              @mouseout="cellMouseOut($event)"
            >
              <span class="orig">{{
                getUsersForPeriod(period, "s").join(", ")
              }}</span>
              <span class="alt">{{ selectedUser.code }}</span>
            </td>
            <td v-else>{{ getUsersForPeriod(period, "s").join(", ") }}</td>
          </tr>
        </tbody>
      </table>
    </article>

    <article v-if="selectedView.name === 'days'" class="days">
      <header>
        <div v-if="admin" class="opened">
          Gewünschte Anpassungen in dieser Tabelle setzen und danach die
          Excel-Liste exportieren.
        </div>
        <div v-else class="closed">
          Das Ändern dieser Listen ist Disponenten/PL vorbehalten.<br />
          Wünsche bezüglich Abtausch einzelner Tage können an die entsprechenden
          Stellen gerichtet werden.
        </div>
      </header>
      <section>
        <table v-for="(period, i) in selectedSeason.periods" :key="i">
          <thead>
            <tr>
              <th>KW</th>
              <th>Datum</th>
              <th>Pikett</th>
              <th>Stv</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(day, j) in period.days"
              :key="j"
              :class="{ current: isCurrentPeriod(period) }"
            >
              <th>{{ day.kw }}</th>
              <th>{{ day.title }}</th>
              <td
                v-if="admin"
                class="clickable"
                :data-name="'p_' + day.date"
                @click="cellClicked($event)"
                @mouseover="cellMouseOver($event)"
                @mouseout="cellMouseOut($event)"
              >
                <span class="orig">{{ getUserForDay(day, "p") }}</span>
                <span class="alt">{{ selectedUser.code }}</span>
              </td>
              <td v-else>{{ getUserForDay(day, "p") }}</td>
              <td
                v-if="admin"
                class="clickable"
                :data-name="'s_' + day.date"
                @click="cellClicked($event)"
                @mouseover="cellMouseOver($event)"
                @mouseout="cellMouseOut($event)"
              >
                <span class="orig">{{ getUserForDay(day, "s") }}</span>
                <span class="alt">{{ selectedUser.code }}</span>
              </td>
              <td v-else>{{ getUserForDay(day, "s") }}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </article>
    <Export
      v-if="exporting"
      :appData="appData"
      :selectedSeason="selectedSeason"
      :selectedSeasonShortTitle="selectedSeasonShortTitle"
      @finished="exporting = false"
    />
  </section>
</template>

<script>
const Export = () => import("./Export.vue");
export default {
  name: "Planner",
  components: {
    Export,
  },
  props: {
    appData: Object,
    userData: Object,
  },
  computed: {
    admin() {
      return (
        this.userData.roles.includes("transitgas-pl") ||
        this.userData.roles.includes("transitgas-pl-stv") ||
        this.userData.roles.includes("transitgas-disp") ||
        this.userData.roles.includes("superuser")
      );
    },
    staff() {
      return this.userData.roles.includes("transitgas-staff");
    },
    expired() {
      let d = new Date();
      d.setYear(this.selectedSeason.start.substr(0, 4));
      d.setMonth(4);
      d.setDate(15);
      d.setHours(23);
      d.setMinutes(59);
      d.setSeconds(59);
      let now = new Date();
      return now > d;
    },
    selectedSeason() {
      return this.appData.seasons
        ? this.appData.seasons[this.selectedSeasonIndex]
        : null;
    },
    initialSeason() {
      let latestSeason = this.appData.seasons.length - 1;
      let initialSeason = latestSeason;
      if (this.admin) {
        // For staff members, display the latest season for registering.
        // For admins, display the current season.
        let latestSeasonStart = new Date(latestSeason.start);
        let now = new Date();
        if (initialSeason > 0 && now < latestSeasonStart) {
          initialSeason--;
        }
      }
      return initialSeason;
    },
    selectedSeasonShortTitle() {
      let s = this.selectedSeason;
      return s.start.substr(0, 4) + "-" + s.end.substr(0, 4);
    },
    selectedView() {
      return this.views[this.selectedViewIndex];
    },
    selectedUser() {
      return this.appData.users
        ? this.appData.users[this.selectedUserIndex]
        : null;
    },
  },
  data() {
    return {
      views: [
        {
          name: "periods",
          label: "Perioden",
        },
        {
          name: "days",
          label: "Tage",
        },
      ],
      selectedSeasonIndex: null,
      selectedViewIndex: 0,
      selectedUserIndex: 0,
      openedSelector: null,
      exporting: false,
    };
  },
  mounted() {
    this.selectedSeasonIndex = this.initialSeason;
  },
  methods: {
    toggleSelector(name) {
      this.openedSelector = this.openedSelector === name ? null : name;
    },
    isCurrentPeriod(period) {
      let start = new Date(period.start);
      let end = new Date(period.end);
      let now = new Date();
      return start <= now && now <= end;
    },
    getUsersForPeriod(period, ps) {
      let periodUsers = period.users[ps];
      let users = [];
      for (let id in periodUsers) {
        let user = this.getUserById(parseInt(id));
        if (user) {
          let days = parseInt(periodUsers[id]);
          users.push(user.code + (days === 14 ? "" : ` (${days}x)`));
        }
      }
      return users;
    },
    getUserForDay(day, ps) {
      let user = this.getUserById(parseInt(day[ps]));
      return user ? user.code : "";
    },
    getUserById(id) {
      return this.appData.users.find((user) => user.id === id);
    },
    cellMouseOver(e) {
      if (e.target.innerText === "") {
        e.target.classList.add("hovered", "add");
      } else if (e.target.innerText === this.selectedUser.code) {
        e.target.classList.add("hovered", "remove");
      }
    },
    cellMouseOut(e) {
      e.target.classList.remove("hovered", "add", "remove");
    },
    cellClicked(e) {
      let newUserId;
      let cellOriginalText = e.target.querySelector("span.orig").innerText;
      if (cellOriginalText === "") {
        newUserId = this.selectedUser.id;
      } else if (cellOriginalText === this.selectedUser.code) {
        newUserId = null;
      } else {
        return;
      }
      this.$emit("cellclicked", {
        fieldName: e.target.dataset.name,
        userId: newUserId,
      });
    },
  },
};
</script>

<style scoped>
#planner {
  padding: 1em 0.5em;
}
#planner > header {
  display: flex;
  flex-wrap: wrap;
}
label ul {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #222;
}
label.active ul {
  display: block;
  padding: 0.5em 0;
  min-width: 100%;
}
label li {
  min-width: calc(100% - 2em);
  margin: 0;
  padding: 0.5em 1em;
  font-weight: normal;
  color: #fff;
  white-space: nowrap;
}
label li.active {
  background-color: rgba(255, 255, 255, 0.25);
}
label li:hover {
  background-color: #0061c8;
}

article header div {
  margin: 1em 0;
  padding: 1em;
  background: rgba(119, 141, 131, 0.25);
}
article header div.opened {
  background: rgba(30, 200, 120, 0.25);
}
article header div.closed {
  background: rgba(240, 0, 60, 0.25);
}
#planner > article {
  margin-bottom: 1em;
}
#planner > .days > section {
  display: flex;
  flex-wrap: wrap;
  gap: 2em 1em;
}
tr.current {
  background-color: rgba(255, 221, 0, 0.5);
}
tr.weekend {
  background-color: rgba(0, 0, 0, 0.05);
}
tbody td {
  font-weight: bold;
  color: #094;
}
td {
  min-width: 3em;
}
td.clickable {
  cursor: pointer;
}
td span {
  pointer-events: none;
}
td span.alt {
  display: none;
  color: rgba(255, 255, 255, 0.75);
}
td.hovered span.orig {
  display: none;
}
td.hovered span.alt {
  display: block;
}
td.add {
  background-color: #094;
}
td.remove {
  text-decoration: line-through;
  background-color: #b00;
}
</style>